//teste dev
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import BlogsSection from "../../../components/blogsSection/BlogsSection";
import toBRL from '../../../components/funcoes/toBRL';
import Donut from "../../../components/graficos/donutPaineis/Donut";
import SimpleModal from "../../blog/ModalProjeto";
import SimpleModal1 from "../../blog/ModalRealizacao";
import "../old_home.css";
import parse from 'html-react-parser';
import Slider from "./components/slider/Slider";
import "./paginaInicial.css";

//icons
// import BGinternacionalizacao from "./background/internacionalizacao.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import Ondas from "../../../components/icons/paineis/ondas.svg";
import Portas from "../../../portas";
import BGinteriorizacao from "./background/backgroundinteriorizacao.svg";
import BGinternacionalizacao from "./background/backgroundinternacionalizacao.svg";
import BGInovacaoEmpresarial from "./background/inovacaoempresarial.svg";
import BGInovacaoPublica from "./background/inovacaopublica.svg";

import { ReactComponent as LogoInovacaoEmpresarialHover } from "../../../components/icons/paineis/InovacaoempresarialHover.svg";
import { ReactComponent as LogoAuxilioaProjetosHover } from "../../../components/icons/paineis/auxilioProjetosHover.svg";
import { ReactComponent as LogoAuxilioaProjetos } from "../../../components/icons/paineis/auxilioaprojsvg.svg";
import { ReactComponent as LogoFormacaoDeRecursosHumanosHover } from "../../../components/icons/paineis/formacaoRhHover.svg";
import { ReactComponent as LogoFormacaoDeRecursosHumanos } from "../../../components/icons/paineis/formacaoderecursoshumanossvg.svg";
import { ReactComponent as LogoCientistaChefeHover } from "../../../components/icons/paineis/inovacaoPublicaHover.svg";
import { ReactComponent as LogoInovacaoEmpresarial } from "../../../components/icons/paineis/inovacaoempresarialsvg.svg";
import { ReactComponent as LogoInternacionalizacaoHover } from "../../../components/icons/paineis/internacionalizacaoHover.svg";
import { ReactComponent as LogoCientistaChefe } from "../../../components/icons/paineis/logoCientistaChefe.svg";
import { ReactComponent as LogoInteriorizacao } from "../../../components/icons/paineis/proginteriorizacaosvg.svg";
import { ReactComponent as LogoInternacionalizacao } from "../../../components/icons/paineis/proginternacionalizacaosvg.svg";
import { ReactComponent as LogoInteriorizacaoHover } from "../../../components/icons/paineis/programaInteriorizacaoHover.svg";

const CssTextField = withStyles({
  root: {
    "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: "#20764B",
    },
    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: "#405965",
    },
    "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: "#20764B",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#405965",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#20764B",
    },
    "& .Mui-focused:after": {
      borderBottomColor: "#20764B",
    },
  },
})(TextField);

function clicarNoLink(link) {
  //window.location="http://www.google.com.br"
  window.open("https://drive.google.com/drive/folders/1Tx01-TtXi-_iotvjFu5-HnA5t7enYT8p?usp=share_link", "_blank");
}


const useStyles = makeStyles({
  selectRoot: {
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
  select: {
    "&:before": {
      borderColor: "#20764B",
    },
    "&:after": {
      borderColor: "#20764B",
    },
    color: "#405965",
    fontWeight: "bold",
  },
  icon: {
    fill: "#20764B",
  },
});

export default function Home({ changeCurrentPage }) {

  const [icone1Hover, setIcone1Hover] = useState(false);
  const [icone2Hover, setIcone2Hover] = useState(false);
  const [icone3Hover, setIcone3Hover] = useState(false);
  const [icone4Hover, setIcone4Hover] = useState(false);
  const [icone5Hover, setIcone5Hover] = useState(false);
  const [icone6Hover, setIcone6Hover] = useState(false);

  const classes = useStyles();
  const [searchField, setSearchField] = useState("");
  const [page, setPage] = useState(0);
  const [selectedProjeto, setSelectedProjeto] = useState("");
  const [selectedRealizacao, setSelectedRealizacao] = useState("");
  const [content, setContent] = useState([])
  const [realizacoes, setRealizacoes] = useState([])
  const [tipos, setTipos] = useState("");
  const [anos, setAnos] = useState([2018, 2019, 2020, 2021, 2022]);
  const [minYear, setMinYear] = useState(2022);
  const [maxYear, setMaxYear] = useState(2022);
  const [data, setData] = useState([
    { ano: 2018, type: "Apoio à Ciência", value: 39141552.00 },
    { ano: 2018, type: "Apoio à Inovação", value: 13609844.00 },
    { ano: 2019, type: "Apoio à Ciência", value: 37622680.00 },
    { ano: 2019, type: "Apoio à Inovação", value: 22450196.00 },
    { ano: 2020, type: "Apoio à Ciência", value: 52231846.00 },
    { ano: 2020, type: "Apoio à Inovação", value: 20899222.00 },
    { ano: 2021, type: "Apoio à Ciência", value: 50194366.00 },
    { ano: 2021, type: "Apoio à Inovação", value: 25802848.00 },
    { ano: 2022, type: "Apoio à Ciência", value: 65450479.00 },
    { ano: 2022, type: "Apoio à Inovação", value: 39299976.00 },
  ]);
  const [blogs, setBlogs] = useState(allBlogs);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [totais, setTotais] = useState(104750455.00); //todos os valores de todos os anos

  const [filterData, setFilterData] = useState([
    { type: "Apoio à Ciência", value: 65450479.00 },
    { type: "Apoio à Inovação", value: 39299976.00 },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleClickProjeto(id) {
    setSelectedProjeto(id);
    setOpen2(true);
  }

  function handleClickRealizacao(id) {
    setSelectedRealizacao(id);
    setOpen1(true);
  }

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }


  function updateFilterData(minYear, maxYear) {
    let array = [...filterData]
    array[0].value = 0
    array[1].value = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].ano >= minYear && data[i].ano <= maxYear) {
        if (data[i].type === "Apoio à Ciência") {
          array[0].value += data[i].value
        }
        if (data[i].type === "Apoio à Inovação") {
          array[1].value += data[i].value
        }
      }
    }
    setFilterData(array)
    setTotais(array[0].value + array[1].value)
  }

  function submit(e) {
    e.preventDefault();
    setSearchField(document.getElementById("searchfield").value);
  }

  const handleChangeMinYear = (event) => {
    var min;
    var max;

    if (event.target.value > maxYear) {
      min = maxYear;
      max = event.target.value;
      setMinYear(maxYear);
      setMaxYear(event.target.value);
      updateFilterData(min, max)
    } else {
      min = event.target.value;
      max = maxYear;
      setMinYear(event.target.value);
      updateFilterData(min, max)
    }


  };

  const handleChangeMaxYear = (event) => {
    var min;
    var max;

    if (event.target.value < minYear) {
      min = event.target.value;
      max = minYear;
      setMaxYear(minYear);
      setMinYear(event.target.value);
      updateFilterData(min, max)
    } else {
      min = minYear;
      max = event.target.value;
      setMaxYear(event.target.value);
      updateFilterData(min, max)
    }

  };

  var allBlogs = [
    {
      tipo: 1,
    }
  ];

  const handleChange = (event) => {
    setTipos(event.target.value);

    if (event.target.value === "") {
      setBlogs(allBlogs);
    } else {
      var filtro = [];
      for (var i = 0; i < allBlogs.length; i++) {
        if (allBlogs[i].tipo === event.target.value) {
          filtro.push(allBlogs[i]);
        }
      }
      if (event.target.value === 1 || event.target.value === 2)
        setBlogs(filtro);
    }
  };

  window.addEventListener("pageshow", function (event) {
    var historyTraversal =
      event.persisted ||
      (typeof window.performance != "undefined" &&
        window.performance.navigation.type === 2);
    if (historyTraversal) {
      window.location.reload();
    }
  });

  const getContent = async () => {
    try {

      const response = await fetch(Portas().serverHost + "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON = await response.json();

      const response2 = await fetch(Portas().serverHost + "/realizacoes?image=true&area=true&projeto=true&anexo=true&pagina=0&tamanho=5&paginacao=false&autor=false",
        {
          method: "GET",
        }
      );

      const resJSON2 = await response2.json();

      let arr = []

      for (var i = 0; i < resJSON.length; i++) {
        arr.push(
          {
            type: "projeto",
            content: resJSON[i]
          }
        )
      }

      for (var i = 0; i < resJSON2.length; i++) {
        arr.push(
          {
            type: "realização",
            content: resJSON2[i]
          }
        )
      }

      setRealizacoes(resJSON2.sort(function (a, b) { return a.createdAt > b.createdAt ? -1 : 1; }))
      setContent(arr.sort(function (a, b) { return a.content.createdAt > b.content.createdAt ? -1 : 1; }))

    }
    catch (err) {

    }
  }

  function filterDuvidas(realizacoes) {
    var count = 0;
    var arr = [];

    for (var i = 0; i < realizacoes.length; i++) {
      if (realizacoes[i].area_realizacao.area === "Dúvidas frequentes" && count < 2) {
        arr.push(
          realizacoes[i]
        )
        count++
      }
    }

    return arr;

  }

  function filterContent(content) {

    let arr = [];

    for (var i = 0; i < content.length; i++) {
      if (content[i].content.titulo.toUpperCase().includes(searchField.toUpperCase())) {
        arr.push(content[i])
      }
    }

    return arr;

  }

  function filterContentList(content) {

    let arr = [];

    for (var i = 0; i < content.length; i++) {
      if (i >= 5 * (page) && i < 5 * (page + 1)) {
        arr.push(content[i])
      }
    }

    return arr;

  }

  function getIndicadorNumber(indi) {
    var data = [
      { ano: 2018, ind: "Bolsistas", valor: 4742 },
      { ano: 2018, ind: "Instituições Beneficiadas", valor: 35 },
      { ano: 2018, ind: "Cidades", valor: 43 },
      { ano: 2018, ind: "Editais", valor: 10 },
      { ano: 2018, ind: "Projetos empresariais fomentados", valor: 46 },
      { ano: 2019, ind: "Bolsistas", valor: 2439 },
      { ano: 2019, ind: "Instituições Beneficiadas", valor: 33 },
      { ano: 2019, ind: "Cidades", valor: 42 },
      { ano: 2019, ind: "Editais", valor: 6 },
      { ano: 2019, ind: "Projetos empresariais fomentados", valor: 26 },
      { ano: 2020, ind: "Bolsistas", valor: 0 },
      { ano: 2020, ind: "Instituições Beneficiadas", valor: 35 },
      { ano: 2020, ind: "Cidades", valor: 42 },
      { ano: 2020, ind: "Editais", valor: 9 },
      { ano: 2020, ind: "Projetos empresariais fomentados", valor: 17 },
      { ano: 2021, ind: "Bolsistas", valor: 0 },
      { ano: 2021, ind: "Instituições Beneficiadas", valor: 31 },
      { ano: 2021, ind: "Cidades", valor: 42 },
      { ano: 2021, ind: "Editais", valor: 9 },
      { ano: 2021, ind: "Projetos empresariais fomentados", valor: 37 },
      { ano: 2022, ind: "Bolsistas", valor: 0 },
      { ano: 2022, ind: "Instituições Beneficiadas", valor: 78 },
      { ano: 2022, ind: "Cidades", valor: 123 },
      { ano: 2022, ind: "Editais", valor: 9 },
      { ano: 2022, ind: "Projetos empresariais fomentados", valor: 69 }
    ]

    var count = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].ano >= minYear && data[i].ano <= maxYear) {
        if (data[i].ind === indi) {
          count += data[i].valor
        }
      }
    }

    return count
  }

  useEffect(() => {
    getContent();
    //getRealizacoes();
  }, []);


  return (
    <div className="root">
      {open1 ? (
        <SimpleModal1
          open={open1}
          setOpen={() => setOpen1(!open1)}
          id={selectedRealizacao}
        ></SimpleModal1>
      ) : (
        <></>
      )}
      {open2 ? (
        <SimpleModal
          open={open2}
          setOpen={() => setOpen2(!open2)}
          id={selectedProjeto}
        ></SimpleModal>
      ) : (
        <></>
      )}
      <div>
        <div
          className="backgroundPainelHome"
          style={{
            backgroundImage: `url(${Ondas})`,
            backgroundPosition: "50% 5%",
          }}
        >
          <div style={{ display: "flex", width: "100vw", gap: "1%", paddingLeft: "6%" }}>
            <Slider />
            <div className="divAtalhosHome">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  marginBottom: "20px"
                }}
              ><div
                className="divAtalhoPrincipalHome1"
                onClick={() => {
                  window.location = "/ciencia/interiorizacao";
                }}
                style={{
                  backgroundImage: `url(${BGinteriorizacao})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "100% 5%",
                }}
                onMouseOver={() => setIcone1Hover(true)}
                onMouseLeave={() => setIcone1Hover(false)}
              >
                  <div className="divLogoAtalhoPrincipal">
                    {icone1Hover ?
                      <SvgIcon style={{ fontSize: "60px", color: "#FFFFFF" }}>
                        <LogoInteriorizacaoHover />
                      </SvgIcon>
                      :
                      <SvgIcon style={{ fontSize: "51.54px", color: "#FFFFFF" }}>
                        <LogoInteriorizacao />
                      </SvgIcon>
                    }

                  </div>
                  <div className="iconTitleAtalhoPrincipalHome">
                    PROGRAMAS DE INTERIORIZAÇÃO
                  </div>
                </div>
                {/* <div style={{ display: "flex" }}>
                  <div
                    className="divAtalhoSecundarioProjetoHome1"
                    onClick={() => {
                      window.location = "/ciencia/interiorizacao";
                    }}
                  >
                    <SvgIcon style={{ fontSize: "38px", color: "#FFFFFF" }}>
                      <IconCeara />
                    </SvgIcon>
                    <div className="iconTitleAtalhoSecundarioHome">
                      INTERIORIZAÇÃO
                    </div>
                  </div>
                  <div
                    className="divAtalhoSecundarioRealizacaoHome1"
                    onClick={() => {changeCurrentPage("indicadores", 2) clicarNoLink() }}
                  >
                    <SvgIcon style={{ fontSize: "38px", color: "#FFFFFF" }}>
                      <Destaques />
                    </SvgIcon>
                    <div className="iconTitleAtalhoSecundarioHome">
                      SISTEMA DE CT&I
                    </div>
                  </div>
                </div> */}
                <div
                  className="divAtalhoPrincipalHome2"
                  onClick={() => {
                    window.location = "/ciencia/internacionalizacao";
                  }}
                  style={{
                    backgroundImage: `url(${BGinternacionalizacao})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 5%",
                  }}
                  onMouseOver={() => setIcone2Hover(true)}
                  onMouseLeave={() => setIcone2Hover(false)}
                >
                  <div className="divLogoAtalhoPrincipal">
                    {icone2Hover ?
                      <SvgIcon style={{ fontSize: "60px", color: "#FFFFFF" }}>
                        <LogoInternacionalizacaoHover />
                      </SvgIcon>
                      :
                      <SvgIcon style={{ fontSize: "51.54px", color: "#FFFFFF" }}>
                        <LogoInternacionalizacao />
                      </SvgIcon>
                    }
                  </div>
                  <div className="iconTitleAtalhoPrincipalHome">
                    PROGRAMAS DE INTERNACIONALIZAÇÃO
                  </div>
                </div>
                <div
                  className="divAtalhoPrincipalHome2"
                  onClick={() => {
                    window.location = "/inovacao/publica";
                  }}
                  style={{
                    backgroundImage: `url(${BGInovacaoPublica})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 5%",
                  }}
                  onMouseOver={() => setIcone3Hover(true)}
                  onMouseLeave={() => setIcone3Hover(false)}
                >
                  <div className="divLogoAtalhoPrincipal">
                    {icone3Hover ?
                      <SvgIcon style={{ fontSize: "60px", color: "#FFFFFF" }}>
                        <LogoCientistaChefeHover />
                      </SvgIcon>
                      :
                      <SvgIcon style={{ fontSize: "51.54px", color: "#FFFFFF" }}>
                        <LogoCientistaChefe />
                      </SvgIcon>
                    }
                  </div>
                  <div className="iconTitleAtalhoPrincipalHome">
                    INOVAÇÃO PÚBLICA CIENTISTA-CHEFE
                  </div>
                </div>
                <div
                  className="divAtalhoPrincipalHome4"
                  onClick={() => {
                    window.location = "/inovacao/empresarial";
                  }}
                  style={{
                    backgroundImage: `url(${BGInovacaoEmpresarial})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 5%",
                  }}
                  onMouseOver={() => setIcone4Hover(true)}
                  onMouseLeave={() => setIcone4Hover(false)}
                >
                  <div className="divLogoAtalhoPrincipal">
                    {icone4Hover ?
                      <SvgIcon style={{ fontSize: "60px", color: "#FFFFFF" }}>
                        <LogoInovacaoEmpresarialHover />
                      </SvgIcon>
                      :
                      <SvgIcon style={{ fontSize: "55.54px", color: "#FFFFFF", marginLeft: "-24px" }}>
                        <LogoInovacaoEmpresarial />
                      </SvgIcon>
                    }
                  </div>
                  <div className="iconTitleAtalhoPrincipalHome">
                    INOVAÇÃO EMPRESARIAL
                  </div>
                </div>
                <div
                  className="divAtalhoPrincipalHome4"
                  onClick={() => {
                    window.location = "/ciencia/auxilio";
                  }}
                  style={{
                    backgroundImage: `url(${BGInovacaoEmpresarial})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 5%",
                  }}
                  onMouseOver={() => setIcone5Hover(true)}
                  onMouseLeave={() => setIcone5Hover(false)}
                >
                  <div className="divLogoAtalhoPrincipal">
                    {icone5Hover ?
                      <SvgIcon style={{ fontSize: "60px", color: "#FFFFFF" }}>
                        <LogoAuxilioaProjetosHover />
                      </SvgIcon>
                      :
                      <SvgIcon style={{ fontSize: "51.54px", color: "#FFFFFF" }}>
                        <LogoAuxilioaProjetos />
                      </SvgIcon>
                    }
                  </div>
                  <div className="iconTitleAtalhoPrincipalHome">
                    Auxílio a projetos científicos
                  </div>
                </div>
                {/*
                <div style={{ display: "flex" }}>
                  <div
                    className="divAtalhoSecundarioProjetoHome2"
                    style={{
                      borderRadius: "0px 0px 0px 10px",
                    }}
                    onClick={() => {
                      window.location = "/inovacao/publica";
                    }}
                  >
                    <SvgIcon style={{ fontSize: "38px", color: "#FFFFFF" }}>
                      <Arquivo />
                    </SvgIcon>
                    <div
                      className="iconTitleAtalhoSecundarioHome"
                      style={{ marginLeft: "15px", marginRight: "15px" }}
                    >
                      INOVAÇÃO PUBLICA
                    </div>
                  </div>
                  <div
                    className="divAtalhoSecundarioRealizacaoHome2"
                    style={{
                      borderRadius: "0px 0px 10px 0px"
                    }}
                    onClick={() => {
                      window.location = "/inovacao/empresarial";
                    }}
                  >
                    <SvgIcon style={{ fontSize: "38px", color: "#FFFFFF" }}>
                      <Arquivo />
                    </SvgIcon>
                    <div className="iconTitleAtalhoSecundarioHome">
                      INOVAÇÃO EMPRESARIAL
                    </div>
                  </div>
                </div>
                  */}
                <div
                  className="divAtalhoPrincipalHome3"
                  onClick={() => {
                    window.location = "/ciencia/formacaorh";
                  }}
                  style={{
                    backgroundImage: `url(${BGInovacaoEmpresarial})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 5%",
                  }}
                  onMouseOver={() => setIcone6Hover(true)}
                  onMouseLeave={() => setIcone6Hover(false)}
                >
                  <div className="divLogoAtalhoPrincipal">
                    {icone6Hover ?
                      <SvgIcon style={{ fontSize: "60px", color: "#FFFFFF" }}>
                        <LogoFormacaoDeRecursosHumanosHover />
                      </SvgIcon>
                      :
                      <SvgIcon style={{ fontSize: "51.54px", color: "#FFFFFF" }}>
                        <LogoFormacaoDeRecursosHumanos />
                      </SvgIcon>
                    }
                  </div>
                  <div className="iconTitleAtalhoPrincipalHome">
                    Formação de recursos Humanos
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style={{
            display: "flex",
            marginTop: "25px",
            width: "100vw",
            justifyContent: "center",
            boxShadow: "inset 0px 65px 39px -25px rgba(0,0,0,0.1)"
          }}>
            <div className="painelHome">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  placeContent: "center"
                }}
              >
                <div style={{
                  width: "100%"
                }}>
                  <div className="textPainelHome">COMO E QUANTO INVESTIMOS</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      justifyItems: "center",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <div className="text2PainelHome">
                      Investimentos realizados de
                    </div>
                    <div
                      style={{
                        display: "flex",
                        background: "lightgrey",
                        width: "100%"
                      }}
                    >
                      <div className="divDropdownPeriodoHome">
                        <FormControl className="dropdownPeriodoHome">
                          <Select
                            value={minYear}
                            onChange={handleChangeMinYear}
                            displayEmpty
                            disableUnderline
                            classes={{ root: classes.selectRoot }}
                            className={classes.select}
                            MenuProps={{ disablePortal: true }}
                            inputProps={{
                              "aria-label": "Without label",
                              classes: {
                                icon: classes.icon,
                                root: classes.root,
                              },
                            }}
                          >
                            {anos.map((ano) => (
                              <MenuItem value={ano}>{ano}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="text2PainelHome">a</div>
                      <div className="divDropdownPeriodoHome">
                        <FormControl className="dropdownPeriodoHome">
                          <Select
                            value={maxYear}
                            onChange={handleChangeMaxYear}
                            displayEmpty
                            disableUnderline
                            classes={{ root: classes.selectRoot }}
                            className={classes.select}
                            MenuProps={{ disablePortal: true }}
                            inputProps={{
                              "aria-label": "Without label",
                              classes: {
                                icon: classes.icon,
                                root: classes.root,
                              },
                            }}
                          >
                            {anos.map((ano) => (
                              <MenuItem value={ano}>{ano}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="text3PainelHome">
                    <b>{toBRL(totais)}</b>
                  </div>
                </div>
                <div className="donutChartHome">
                  <Donut data={filterData} cor={"verde"}></Donut>
                </div>
                {/*<div className="divIconesHome">
                  
                  <div className="divIconeHome" style={{ marginRight: "70px" }}>
                    <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                      <Bolsistas />
                    </SvgIcon>
                    <p className="iconeTituloHome">
                      <b className="iconeNumeroHome">
                        <Animacao data={getIndicadorNumber("Bolsistas")} />
                      </b>
                      <br></br>Bolsistas<br></br>
                    </p>
                  </div>
                          
                  <div className="divIconeHome" style={{ marginRight: "70px" }}>
                    <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                      <ProgramasPos />
                    </SvgIcon>
                    <p className="iconeTituloHome">
                      <b className="iconeNumeroHome">
                        <Animacao data={getIndicadorNumber("Instituições Beneficiadas")} />
                      </b>
                      <br></br>Instituições Beneficiadas<br></br>
                    </p>
                  </div>

                  <div className="divIconeHome" style={{ marginRight: "70px" }}>
                    <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                      <Projetos />
                    </SvgIcon>
                    <p className="iconeTituloHome">
                      <b className="iconeNumeroHome">
                        <Animacao data={getIndicadorNumber("Cidades")} />
                      </b>
                      <br></br>Cidades<br></br>
                    </p>
                  </div>
                  <div className="divIconeHome" style={{ marginRight: "70px" }}>
                    <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                      <Editais />
                    </SvgIcon>
                    <p className="iconeTituloHome">
                      <b className="iconeNumeroHome">
                        <Animacao data={getIndicadorNumber("Editais")} />
                      </b>
                      <br></br>Editais<br></br>
                    </p>
                  </div>
                  <div className="divIconeHome">
                    <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                      <EmpresasFomentadas />
                    </SvgIcon>
                    <p className="iconeTituloHome">
                      <b className="iconeNumeroHome">
                        <Animacao data={getIndicadorNumber("Projetos empresariais fomentados")} />
                      </b>
                      <br></br>Projetos empresariais fomentados<br></br>
                    </p>
                  </div>
                </div>*/}
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="divButtonSaibaMaisPainelInicialHome"
                  onClick={() => changeCurrentPage("indicadores", 1)}
                >
                  <div className="textButtonSaibaMaisPainelInicialHome">
                    Veja todos os indicadores &#10095;
                  </div>
                </div>
              </div>
              {/*<div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                  marginTop: "20px"
                }}
              >
                <div className="textDataUltimaAtualizacaoPainelInicialHome">
                  Última atualização em: 20/12/2022
                </div>
              </div>*/}
            </div>

            <div className="painelHome">
              <div
                style={{
                  display: "flex"
                }}
              >
                <div style={{
                  width: "100%"
                }}>
                  <div className="textPainelHome">Dúvidas frequentes</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "left",
                      width: "100%",
                      height: "366px"
                    }}
                  >

                    <div >
                      {filterDuvidas(realizacoes).length > 0 ?
                        <div>
                          <div className="divContentAllColumn" style={{ width: "100%" }}>
                            {filterDuvidas(realizacoes).map((item) => (
                              <div
                                className="divContentAllColumn" style={{ width: "100%" }}
                              >
                                <div className="DuvidaTitulo">{item.titulo}</div>
                                <div className="DuvidaConteudo">{parse(item.conteudo)}</div>
                                <div className="DuvidaVejaMais" onClick={() => handleClickRealizacao(item.id)} style={{ marginBottom: "32px", paddingBottom: "8px", borderBottom: "1px solid #D9D9D9" }}>{"Veja mais >"}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                        : <></>
                      }
                    </div>

                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="divButtonSaibaMaisPainelInicialHome"
                  onClick={() => changeCurrentPage("duvidas", 1)}
                >
                  <div className="textButtonSaibaMaisPainelInicialHome">
                    Ver todas as dúvidas frequentes &#10095;
                  </div>
                </div>
              </div>

            </div>
          </div>
          <BlogsSection page={"home"} title={"Últimas noticias"}/>
        </div>
      </div>
    </div>
  );
}

/*tete deploy main*/