import SvgIcon from "@material-ui/core/SvgIcon";
import React, { useEffect, useState } from "react";
import { ReactComponent as Educacao } from '../../../../components/icons/areasCientistaChefe/Educ.svg';
import { ReactComponent as Protecao } from '../../../../components/icons/areasCientistaChefe/Prot.svg';
import { ReactComponent as Agricultura } from '../../../../components/icons/areasCientistaChefe/agricultura.svg';
import { ReactComponent as Aquicultura } from '../../../../components/icons/areasCientistaChefe/aquicult.svg';
import { ReactComponent as BigDatapf } from '../../../../components/icons/areasCientistaChefe/bigdatapf.svg';
import { ReactComponent as Ciencia } from '../../../../components/icons/areasCientistaChefe/ciencia.svg';
import { ReactComponent as Contas } from '../../../../components/icons/areasCientistaChefe/contas.svg';
import { ReactComponent as Costa } from '../../../../components/icons/areasCientistaChefe/costa.svg';
import { ReactComponent as Cultura } from '../../../../components/icons/areasCientistaChefe/cultura.svg';
import { ReactComponent as Dados } from '../../../../components/icons/areasCientistaChefe/dados.svg';
import { ReactComponent as Digital } from '../../../../components/icons/areasCientistaChefe/digital.svg';
import { ReactComponent as Economia } from '../../../../components/icons/areasCientistaChefe/economia.svg';
import { ReactComponent as Energia } from '../../../../components/icons/areasCientistaChefe/energia.svg';
import { ReactComponent as Financaspf } from '../../../../components/icons/areasCientistaChefe/financaspf.svg';
import { ReactComponent as Hid } from '../../../../components/icons/areasCientistaChefe/hid.svg';
import { ReactComponent as Infraestrutura } from '../../../../components/icons/areasCientistaChefe/infraestrutura.svg';
import { ReactComponent as Inovacao } from '../../../../components/icons/areasCientistaChefe/inovacao.svg';
import { ReactComponent as Justica } from '../../../../components/icons/areasCientistaChefe/justica.svg';
import { ReactComponent as MeioAmbiente } from '../../../../components/icons/areasCientistaChefe/meioambiente.svg';
import { ReactComponent as Pecuaria } from '../../../../components/icons/areasCientistaChefe/pecuaria.svg';
import { ReactComponent as Pesca } from '../../../../components/icons/areasCientistaChefe/pesca.svg';
import { ReactComponent as Saude } from '../../../../components/icons/areasCientistaChefe/saude.svg';
import { ReactComponent as Seguranca } from '../../../../components/icons/areasCientistaChefe/seguranca.svg';
import { ReactComponent as Fiscal } from '../../../../components/icons/areasCientistaChefe/fiscal.svg';
import { ReactComponent as Trabalho } from '../../../../components/icons/areasCientistaChefe/trabalho.svg';
import { ReactComponent as Mulheres } from '../../../../components/icons/areasCientistaChefe/mulheres.svg';
import { ReactComponent as DadosSaude } from '../../../../components/icons/areasCientistaChefe/dadosSaude.svg';
import Portas from "../../../../portas";
import "./areas.css";
import SimpleModal from "./blogPerfil/Modal";

export default function Areas() {

    const [open, setOpen] = useState(false);
    const [cientistasGoverno, setCientistasGoverno] = useState([])
    const [cientistasPrefeitura, setCientistasPrefeitura] = useState([])
    const [selectedCientista, setSelectedCientista] = useState("");

    function handleClick(cientista) {
        setSelectedCientista(cientista)
        setOpen(true)
    }

    function selectIcon(area) {
        console.log("updated 2");
        console.log(area);
        if (area === "Big Data") {
            return (<BigDatapf />)
        }
        if (area === "Finanças") {
            return (<Financaspf />)
        }
        if (area === "Tribunal de Contas") {
            return (<Contas />)
        }
        if (area === "Transformação Digital") {
            return (<Digital />)
        }
        if (area === "Segurança Pública") {
            return (<Seguranca />)
        }
        if (area === "Saúde") {
            return (<Saude />)
        }
        if (area === "Recursos Hídricos") {
            return (<Hid />)
        }
        if (area === "Proteção Social") {
            return (<Protecao />)
        }
        if (area === "Pesca") {
            return (<Pesca />)
        }
        if (area === "Meio Ambiente") {
            return (<MeioAmbiente />)
        }
        if (area === "Justiça") {
            return (<Justica />)
        }
        if (area === "Inovação" || area === "Ecossistema de Inovação") {
            return (<Inovacao />)
        }
        if (area === "Infraestrutura" || area === "Infraestrutura Viária") {
            return (<Infraestrutura />)
        }
        if (area === "Energia") {
            return (<Energia />)
        }
        if (area === "Educação") {
            return (<Educacao />)
        }
        if (area === "Economia") {
            return (<Economia />)
        }
        if (area === "Cultura") {
            return (<Cultura />)
        }
        if (area === "Infraestrutura Costeira") {
            return (<Costa />)
        }
        if (area === "Avaliação de Ações de Ciência e Inovação") {
            return (<Ciencia />)
        }
        if (area === "Ciência de Dados") {
            return (<Dados />)
        }
        if (area === "Economia do Mar" || area === "Economia Azul") {
            return (<Aquicultura />)
        }
        if (area === "Agricultura") {
            return (<Agricultura />)
        }
        if (area === "Pecuária") {
            return (<Pecuaria />)
        }
        if (area === "Trabalho") {
            return (<Trabalho />)
        }
        if (area === "Política Fiscal") {
            return (<Fiscal />)
        }
        if (area === "Mulheres" || area === "Cientista Chefe Mulheres") {
            return (<Mulheres />)
        }
        if (area === "Dados da Saúde") {
            return (<DadosSaude />)
        }

        return (<></>)
    }

    const getCientistas = async () => {
        try {
            const response = await fetch(Portas().serverHost + "/areas/bolhas",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();
            console.log(resJSON)

            for (var i = 0; i < resJSON.length; i++) {
                if (resJSON[i].dominio === "Governo") {


                    setCientistasGoverno(resJSON[i].areas)
                }
                if (resJSON[i].dominio === "Prefeitura") {


                    setCientistasPrefeitura(resJSON[i].areas)
                }
            }



        }
        catch (err) {

        }
    }


    useEffect(() => {
        getCientistas()
    }, []);
    return (
        <div className="root">
            <div className="divPainelIconesCientistaChefe">
                {open
                    ?
                    <SimpleModal open={open} setOpen={() => setOpen(!open)} selectedCientista={selectedCientista}></SimpleModal>
                    :
                    <></>
                }
                <p className="areasCientistaTitulo">CONHEÇA OS PROJETOS</p>
                <p className="areasCientistaSubtitulo">Clique sobre uma área para saber mais</p>
                {cientistasGoverno.length > 0 ?
                    <div className="separadorAreasCientista">GOVERNO DO CEARÁ:</div>
                    : <></>
                }
                {cientistasGoverno.length > 0
                    ?
                    <div className="areasCientistaIconsDiv">
                        {cientistasGoverno.map((cientista, index) => {
                            if (cientista.area !== "Saúde" && cientista.area !== "Ciência de Dados" && cientista.area !== "Segurança Pública" && cientista.area !== "Tribunal de Contas") {
                                return <div className="areaCientista">
                                    <div className="divIcone1" onClick={() => handleClick(cientista)}>
                                        <SvgIcon style={{ fontSize: "inherit", color: "#FFFFFF" }}>
                                            {selectIcon(cientista.area)}
                                        </SvgIcon>
                                    </div>
                                    <div style={{ height: "60px" }}>
                                        <p className="areaCientistaTitulo" >{cientista.area}</p>
                                        <p className="areaCientistaSubtitulo">{cientista.cientista.nome}</p>
                                    </div>
                                </div>
                            }
                        })}
                        <div className="areaCientista">
                            <div className="divIcone3">
                                <SvgIcon style={{ fontSize: "inherit", color: "#FFFFFF" }}>
                                    {<Dados />}
                                </SvgIcon>
                            </div>
                            <div style={{ height: "60px" }}>
                                <p className="areaCientistaTitulo" style={{ color: '#D3D3D3' }}>Ciência de Dados</p>
                                <p className="areaCientistaSubtitulo" style={{ color: '#D3D3D3' }}>Em definição de Cientista</p>
                            </div>
                        </div>
                        <div className="areaCientista">
                            <div className="divIcone3">
                                <SvgIcon style={{ fontSize: "inherit", color: "#FFFFFF" }}>
                                    {<Saude />}
                                </SvgIcon>
                            </div>
                            <div style={{ height: "60px" }}>
                                <p className="areaCientistaTitulo" style={{ color: '#D3D3D3' }}>Saúde</p>
                                <p className="areaCientistaSubtitulo" style={{ color: '#D3D3D3' }}>Em definição de Cientista</p>
                            </div>
                        </div>
                        <div className="areaCientista">
                            <div className="divIcone3">
                                <SvgIcon style={{ fontSize: "inherit", color: "#FFFFFF" }}>
                                    {<Seguranca />}
                                </SvgIcon>
                            </div>
                            <div style={{ height: "60px" }}>
                                <p className="areaCientistaTitulo" style={{ color: '#D3D3D3' }}>Segurança Pública</p>
                                <p className="areaCientistaSubtitulo" style={{ color: '#D3D3D3' }}>Em definição de Cientista</p>
                            </div>
                        </div>
                        <div className="areaCientista">
                            <div className="divIcone3">
                                <SvgIcon style={{ fontSize: "inherit", color: "#FFFFFF" }}>
                                    {<Contas />}
                                </SvgIcon>
                            </div>
                            <div style={{ height: "60px" }}>
                                <p className="areaCientistaTitulo" style={{ color: '#D3D3D3' }}>Tribunal de Contas</p>
                                <p className="areaCientistaSubtitulo" style={{ color: '#D3D3D3' }}>Em definição de Cientista</p>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        
                    </>
                }
                {cientistasPrefeitura.length > 0 ?
                    <div className="separadorAreasCientista">PREFEITURA DE FORTALEZA:</div>
                    : <></>
                }
                {cientistasPrefeitura.length > 0
                    ?
                    <div className="areasCientistaIconsDiv">
                        {cientistasPrefeitura.map((cientista, index) => {
                            if (cientista.area !== "Saúde" && cientista.area !== "Ciência de Dados" && cientista.area !== "Segurança Pública" && cientista.area !== "Tribunal de Contas") {
                                return <div className="testeAlinhamento">
                                    <div className="areaCientista">
                                        <div className="divIcone2" onClick={() => handleClick(cientista)}>
                                            <SvgIcon style={{ fontSize: "inherit", color: "#FFFFFF" }}>
                                                {selectIcon(cientista.area)}
                                            </SvgIcon>
                                        </div>
                                        <p className="areaCientistaTitulo">{cientista.area}</p>
                                        <p className="areaCientistaSubtitulo">{cientista.cientista.nome}</p>
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}